export default class ColorSelector {
    constructor(manager, eltName) {
        this.manager = manager
        this.elts = Array.from(document.querySelectorAll(eltName))

        this.onClickBound = this.onClick.bind(this)
        
        this.init()
    }

    init() {
        this.elts.forEach(elt => {
            elt.addEventListener('mouseup', this.onClickBound, false)
        })
    }
    
    onClick(event) {
        const color = event.target.dataset.color
        this.manager.changeColor(color)
    }
    
    destroy() {
        this.elts.forEach(elt => {
            elt.removeEventListener('mouseup', this.onClickBound)
            
        })
        this.elts.length = 0
        this.elts = null

        this.onClickBound = null

        this.manager = null
    }
    
};
