import { Camera } from './Core/Camera.js'
import { Renderer } from './Core/Renderer.js'
import { AnimationLoop } from './Utils/AnimationLoop.js'
import { RenderSize } from './Utils/RenderSize.js'
import { AssetManager } from './Loading/AssetManager.js'
import { Debug } from './Utils/Debug.js'

import assets from "/assets.js"

import * as THREE from 'three'
import { Memory } from './Utils/Memory.js'
import { Lights } from './World/Lights.js'
import Ground from './World/Ground.js'
import UIManager from './UI/UIManager.js'
import State from './State.js'
import gsap from 'gsap'
import Car from './World/Car.js'
import SoundManager from './Utils/SoundManager.js'

let instance = null

export class App {
    constructor(canvas, params= {}) {
        if (instance !== null) {
            return instance
        }
        instance = this

        // Init app config
        this.config = {}
        this.config.noSplashScreen = typeof params.noSplashScreen === 'boolean' ? params.noSplashScreen : false

        this.canvas = canvas
        
        this.updateBound = this.update.bind(this)
        this.assetManagerReadyHandlerBound = this.assetManagerReadyHandler.bind(this)
        this.onToggleCarDrivingBound = this.onToggleCarDriving.bind(this)
        this.onChangeColorBound = this.onChangeColor.bind(this)

        this.debug = new Debug()
        
        this.animationLoop = new AnimationLoop()
        this.animationLoop.on('update', this.updateBound)

        this.renderSize = new RenderSize()
        this.renderer = new Renderer()

        this.camera = new Camera()
        const cameraCenterAngle = Math.PI / 4
        const cameraDeltaAngle = Math.PI / 6
        this.camera.setLimits(1, 9, cameraCenterAngle, cameraCenterAngle + cameraDeltaAngle)
        this.camera.setPositionZ(7)

        this.scene = new THREE.Scene()

        this.ui = null

        this.hasSound = window.location.hash === '#sound'
        if (this.hasSound) {
            this.soundManager = new SoundManager()
        }
        
        this.state = new State()
        
        this.worldGroup = null
        this.ground = null
        this.car = null

        this.assetManager = new AssetManager(assets)       
        this.assetManager.on('ready', this.assetManagerReadyHandlerBound)
        this.assetManager.load()

        this.animationLoop.start()
    }
    
    assetManagerReadyHandler() {
        this.initScene()        
    }
    
    initScene() {
        console.log(`App :: setup the scene`)

        this.lights = new Lights()
        this.scene.add(this.lights.instance)

        this.scene.background = this.assetManager.items.restaurant
        this.scene.backgroundBlurriness = 0.02
        this.scene.backgroundIntensity = 0.1

        this.worldGroup = new THREE.Group()
        this.scene.add(this.worldGroup)

        this.scene.fog = new THREE.Fog(0x000000, -5, 0)

        // Create 3D world objects and add them to the world group
        this.ground = new Ground()
        this.ground.instance.rotation.set(Math.PI / 2, 0, 0)
        this.ground.instance.position.set(0, -0.5, 0)
        this.ground.instance.material.opacity = 0

        this.worldGroup.add(this.ground.instance)

        this.car = new Car()
        this.car.instance.position.set(0, -0.5, 0)
        this.car.instance.rotation.set(0, Math.PI * 0.5, 0)
        this.worldGroup.add(this.car.instance)

        if (this.debug.active) {
            const axis = new THREE.AxesHelper(5)
            this.scene.add(axis)
        }

        this.ground.instance.material.opacity = 1.0
        // this.scene.fog.near = 10
        // this.scene.fog.far = 15

        const tl = gsap.timeline({})
        tl.to(this.scene.fog, {near: 10, ease: "sine.inOut", duration: 2})
        tl.to(this.scene.fog, {far: 15, ease: "sine.inOut", duration: 2}, '<')

        // if (this.hasSound) {
        //     gsap.delayedCall(1, () => {
        //         this.soundManager.playCarStart()
        //     })
        // }

        this.initUI()
    }
    
    initUI() {
        this.ui = new UIManager(document.querySelector('.ui'))
        this.ui.on('changeColor', this.onChangeColorBound)
        this.ui.on('toggleCarDriving', this.onToggleCarDrivingBound)
        if (this.hasSound) {
            this.ui.on('carEngineOff', () => {
                this.soundManager.playCarStop()
            }) 
        }
        
        this.ui.showScreenMenu()
    }
    
    onToggleCarDriving() {
        this.car.toggleDriving()
    }

    onChangeColor(data) {
        this.car.setColor(data.color)
    }
   
    update(event) {
        this.camera.update()

        if (this.ui) {
            this.ui.update()
        }

        if (this.car) {
            this.car.update(event.elapsed, event.delta)
        }

        if (this.ground) {
            this.ground.update(event.delta)
        }

        // Camera rotation mode
        // Display the world with camera instance controlled by OrbitControls
        this.renderer.render(this.scene, this.camera.instance)

        // Object rotation mode
        // Don't use the camera instance. Instead we will move the world group with transposed rotation
        // Render with the fake camera not moving, but supporting the dolly in and out (zoom)
        // this.worldGroup.quaternion.copy(this.camera.instance.quaternion.clone().conjugate())
        // this.renderer.render(this.scene, this.camera.fake)
    }
    
    destroy() {
        this.ui.off('changeColor')
        this.onChangeColorBound = null
    
        this.lights.destroy()
        this.lights = null

        this.ground.destroy()
        this.ground = null

        this.car.destroy()
        this.car = null

        if (this.splashScreen !== null) {
            this.splashScreen.destroy()
            this.splashScreen = null
        }

        Memory.clearScene(this.worldGroup)
        this.worldGroup = null

        this.updateBound = null
        this.onToggleCarDrivingBound = null
        this.assetManagerReadyHandlerBound = null
    }
}