import { App } from "../App";

import { Howl, Howler } from 'howler'

export default class SoundManager {
    constructor() {
        this.app = new App()
        
        
        this.soundCarStart = null
        this.soundCarIdle = null
        this.soundCarStartDrive = null
        this.soundCarDrive = null
        this.soundCarStopDrive = null
        this.soundCarStop = null
        
        this.init()
    }
    
    init() {
        new AudioContext().resume()

        Howler.volume(0.2)

        this.soundCarStart = new Howl({
            src: ['sounds/car-start.mp3'],
            loop: false
        })
        
        this.soundCarIdle = new Howl({
            src: ['sounds/car-idle.mp3'],
            loop: true
        })
        
        this.soundCarStartDrive = new Howl({
            src: ['sounds/car-start-driving.mp3'],
            loop: false
        })
        
        this.soundCarDrive = new Howl({
            src: ['sounds/car-driving.mp3'],
            loop: true
        })
        
        this.soundCarStopDrive = new Howl({
            src: ['sounds/car-stop-driving.mp3'],
            loop: false
        })
        
        this.soundCarStop = new Howl({
            src: ['sounds/car-stopping.mp3'],
            loop: false
        })
    }

    playCarStart() {
        console.log("playCarStart")

        this.soundCarStart.on('end', () => {
            this.soundCarStart.off('end')
            this.soundCarStart.stop()
            
            this.playCarIdle()
        })
        
        this.soundCarStart.play()
    }
    
    playCarIdle() {
        console.log("playCarIdle")
        
        this.soundCarIdle.loop = true
        this.soundCarIdle.play()
    }
    
    playCarStartDriving() {
        console.log("playCarStartDriving")
        
        // this.soundCarIdle.loop = false
        this.soundCarIdle.on('end', () => {
            this.soundCarIdle.off('end')
            
            this.soundCarIdle.stop()
            this.soundCarStartDrive.on('end', () => {
                this.soundCarStartDrive.off('end')

                this.soundCarStartDrive.stop()
                this.soundCarDrive.loop = true
                this.soundCarDrive.play()
            })
            this.soundCarStartDrive.play()
        })
    }

    playCarStopDriving() {
        console.log("playCarStopDriving")

        this.soundCarDrive.on('end', () => {
            this.soundCarDrive.off('end')
            
            this.soundCarDrive.loop = false
            this.soundCarDrive.stop()
            
            this.soundCarStopDrive.play()

            this.soundCarStopDrive.on('end', () => {
                this.soundCarStopDrive.off('end')
                this.soundCarStopDrive.stop()
                this.playCarIdle()
            })
        })
    }

    playCarStop() {
        // this.soundCarIdle.loop = false        
        this.soundCarIdle.on('end', () => {
            this.soundCarIdle.off('end')
            this.soundCarIdle.stop()
            this.soundCarStop.play()
        })
    }

    destroy() {
        this.soundCarStart = null
        this.soundCarIdle = null
        this.soundCarStartDrive = null
        this.soundCarDrive = null
        this.soundCarStopDrive = null
        this.soundCarStop = null
    }
}
