export default class UIButton {
    constructor(manager, eltName, onClick) {
        this.manager = manager
        this.elt = document.querySelector(eltName)
        this.onClickBound = onClick

        this.init()
    }

    init() {
        this.elt.addEventListener('mouseup', this.onClickBound, false)
    }

    destroy() {
        this.elt.removeEventListener('mouseup', this.onClickBound)
        this.elts = null

        this.onClickBound = null

        this.manager = null
    }
};
