import { Memory } from "../Utils/Memory"
import { App } from "../App"
import ModelUtils from "../Utils/ModelUtils"
import { DoubleSide, FrontSide, LinearSRGBColorSpace, MeshNormalMaterial, MeshPhysicalMaterial, MeshStandardMaterial } from "three"
import { ColorRGB } from "../Utils/Utils"
import gsap from 'gsap'

import CAR_DEFINITION from "./Car.def.js"

export default class Car {
    constructor() {
        this.app = new App()

        this.loadedModel = null
        this.instance = null

        this.nodes = null

        this.animDriveSpeed = 0
        this.animCrazyCapotFrequency = 0

        this.init()
    }
    
    init() {
        this.loadedModel = this.app.assetManager.items.LowRider
        if (this.loadedModel.scene) {
            this.instance = this.loadedModel.scene
        }
        else {
            this.instance = this.loadedModel
            this.instance.scale.set(0.01, 0.01, 0.01)
        }
        
        this.nodes = ModelUtils.useNodes(this.instance)
        // this.nodes.forEach(n => console.log(`Node ${n.name}`))

        this.color = 0
        this.colors = ['black', 'white', 'red', 'mix']

        this.initMaterials()
        this.initNodes()
        this.initShadows()
    }

    initMaterials() {

        this.bodyMaterial1 = new MeshStandardMaterial({
            color: new ColorRGB("#707070"),
            side: FrontSide,
            wireframe: false,
            transparent: true,
            map          : this.app.assetManager.items['LowRider_Carrosserie_1'].map[this.colors[this.color]],
            normalMap    : this.app.assetManager.items['LowRider_Carrosserie_1'].normal,
            roughnessMap : this.app.assetManager.items['LowRider_Carrosserie_1'].roughness,
            metalnessMap : this.app.assetManager.items['LowRider_Carrosserie_1'].metalness,
        })

        this.bodyMaterial2 = new MeshStandardMaterial({
            color: new ColorRGB("#707070"),
            side: FrontSide,
            wireframe: false,
            map          : this.app.assetManager.items['LowRider_Carrosserie_2'].map[this.colors[this.color]],
            normalMap    : this.app.assetManager.items['LowRider_Carrosserie_2'].normal,
            roughnessMap : this.app.assetManager.items['LowRider_Carrosserie_2'].roughness,
            metalnessMap : this.app.assetManager.items['LowRider_Carrosserie_2'].metalness,
        })
        
        this.interiorMaterial = new MeshStandardMaterial({
            color: new ColorRGB("#202020"),
            side: FrontSide,
            transparent: true,
            // wireframe: false,
            map          : this.app.assetManager.items['LowRider_Int'].map,
            normalMap    : this.app.assetManager.items['LowRider_Int'].normal,
            roughnessMap : this.app.assetManager.items['LowRider_Int'].roughness,
            metalnessMap : this.app.assetManager.items['LowRider_Int'].metalness,
        })

        this.chassisMaterial = new MeshStandardMaterial({
            color: new ColorRGB("#707070"),
            side: FrontSide,
            transparent: true,
            // wireframe: false,
            map          : this.app.assetManager.items['LowRider_Sols_et_Chassis'].map,
            normalMap    : this.app.assetManager.items['LowRider_Sols_et_Chassis'].normal,
            roughnessMap : this.app.assetManager.items['LowRider_Sols_et_Chassis'].roughness,
            metalnessMap : this.app.assetManager.items['LowRider_Sols_et_Chassis'].metalness,
        })

        this.wheelsMaterial = new MeshStandardMaterial({
            color: new ColorRGB("#202020"),
            side: DoubleSide,
            transparent: true,
            roughness: 0.5,
            metalness: 0.9,
            map          : this.app.assetManager.items['LowRider_Roues_Details'].map,
            normalMap    : this.app.assetManager.items['LowRider_Roues_Details'].normal,
            roughnessMap : this.app.assetManager.items['LowRider_Roues_Details'].roughness,
            metalnessMap : this.app.assetManager.items['LowRider_Roues_Details'].metalness,
        })

        this.glassMaterial = new MeshPhysicalMaterial({
            // emissive: new ColorRGB("#000000"),
            side: DoubleSide,
            transparent: true,
            opacity: 0.25,
            roughness: 0.25,
            metalness: 0.9,
            reflectivity: 1,
            alphaMap: this.app.assetManager.items['LowRider_Roues_Details'].alpha
        })

        Array.from([this.bodyMaterial1, this.bodyMaterial2, this.interiorMaterial, this.chassisMaterial, this.wheelsMaterial]).map((mat) => {
            mat.normalMap.colorSpace = LinearSRGBColorSpace
            mat.roughnessMap.colorSpace = LinearSRGBColorSpace
            mat.metalnessMap.colorSpace = LinearSRGBColorSpace
        })

        if (this.app.debug.active) {
            const gf = this.app.debug.ui.addFolder("Glass Material")
            gf.add(this.glassMaterial, 'transparent')
            gf.add(this.glassMaterial, 'opacity', 0, 1, 0.01)
            gf.add(this.glassMaterial, 'roughness', 0, 1, 0.01)
            gf.add(this.glassMaterial, 'metalness', 0, 1, 0.01)
        }
    }

    initShadows() {
        this.instance.traverse(node => {
            if (node.isMesh) {
                node.castShadow = true
                // node.receiveShadow = true
            }
        })
    }

    initNodes() {
        const materials = {
            'Wheels & details': this.wheelsMaterial,
            'Vitres': this.glassMaterial,
            'Chassis': this.chassisMaterial,
            'Interior': this.interiorMaterial,
            'Carrosserie 1': this.bodyMaterial1,
            'Carrosserie 2': this.bodyMaterial2
        }

        for (const [part, material] of Object.entries(materials)) {
            const nodeNames = CAR_DEFINITION.nodes_by_material[part]
            nodeNames.forEach((name) => {
                // console.log(name)
                const mesh = this.instance.getObjectByName(name)
                if (!mesh || !mesh.isMesh) {
                    // console.warn(`${name} is not a mesh`)
                    return
                }

                mesh.material = material
            })
        }
    }

    nextColor() {
        this.color = (this.color + 1) % this.colors.length
        this.bodyMaterial1.map = this.app.assetManager.items['LowRider_Carrosserie_1'].map[this.colors[this.color]]
        this.bodyMaterial2.map = this.app.assetManager.items['LowRider_Carrosserie_2'].map[this.colors[this.color]]
    }

    setColor(color) {
        if (this.colors.indexOf(color) === -1) {
            console.warn(`Wrong color asked : ${color}`)
            return
        }

        this.color = this.colors.indexOf(color)

        this.bodyMaterial1.map = this.app.assetManager.items['LowRider_Carrosserie_1'].map[color]
        this.bodyMaterial2.map = this.app.assetManager.items['LowRider_Carrosserie_2'].map[color]
    }

    startDriving() {
        const tl = gsap.timeline({})
        tl.to(this, {animDriveSpeed: 5, ease: "sine.inOut", duration: 2})

        this.app.soundManager.playCarStartDriving()
    }
    
    stopDriving() {
        const tl = gsap.timeline({})
        tl.to(this, {animDriveSpeed: 0, ease: "sine.inOut", duration: 2})
        
        this.app.soundManager.playCarStopDriving()
    }

    toggleDriving() {
        if (this.animDriveSpeed === 0) {
            this.startDriving()
            if (this.app.hasSound) {
                this.trigger('carStartDriving')
            }            
        }
        else {
            this.stopDriving()
            if (this.app.hasSound) {
                this.trigger('carStopDriving')
            }
        }
    }

    updateWheelsRotation(delta) {
        const speed = delta * this.animDriveSpeed
        this.instance.getObjectByName('ANM_Roue_Arriere_L').rotation.x += speed
        this.instance.getObjectByName('ANM_Roue_Arriere_R').rotation.x += speed
        this.instance.getObjectByName('ANM_Roue_Avant_L').rotation.x += speed
        this.instance.getObjectByName('ANM_Roue_Avant_R').rotation.x += speed
    }
    
    updateCapot(elapsed) {
        // this.instance.getObjectByName('ANM_Capot').rotation.x = -0.5 * (Math.sin (2 * Math.PI * elapsed * this.animCrazyCapotFrequency) + 1)        
    }
    
    update(elapsed, delta) {
        this.updateWheelsRotation(delta)
        this.updateCapot(elapsed)
    }

    destroy() {
        this.nodes.length = 0
        this.nodes = null

        this.colors.length = 0
        this.colors = null

        Memory.releaseObject3D(this.instance)
        this.instance = null
        this.loadedModel = null        
    }   
}
