import { Color, Vector2, Vector3 } from "three"

export class Utils {

    static worldToScreen(worldPt, camera, size) {
        const projPt = worldPt.clone()
        projPt.project(camera)
        return new Vector2(projPt.x * size.x * 0.5, -projPt.y * size.y * 0.5)
    }

    static Vector2FromVector3(vec3) {
        return new Vector3(vec3.x, vec3.y, 0)
    }
}

export class ColorRGB extends Color {
    constructor(r, g, b) {
        super(r, g, b)

        this.convertLinearToSRGB()
    }

}