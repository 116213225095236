import { App } from "../App"
import { EventEmitter } from "../Utils/EventEmitter"
import { Utils } from "../Utils/Utils"

import ColorSelector from "./Screen/ColorSelector"
import UIButton from "./Screen/UIButton"
import CarDriveToggleButton from "./Screen/UIButton"

export default class UIManager extends EventEmitter {
    constructor(container) {
        super()

        this.container = container
        
        this.carToggleDrivingButton = null
        this.colorSelector = null

        this.mainWorld = null
        this.mainScreen = null

        this.app = new App()
        
        this.init()
    }
    
    init() {
        this.initScreen()
        this.initWorld()
    }
    
    initScreen() {
        // Init screen space DOM menu objects
        this.mainScreen = document.querySelector('.ui-screen')

        this.colorSelector = new ColorSelector(this, '.color-selector-button')
        this.carToggleDrivingButton = new UIButton(this, '.car-toggle-driving-button', this.toggleCarDriving.bind(this))
        // this.carEngineOffButton = new UIButton(this, '.car-engine-off-button', this.carStopEngine.bind(this))
    }
    
    initWorld() {
        this.mainWorld = document.querySelector('.ui-3d')
    }

    // Convert world point into screen point to keep DOM objects position relative to 3D space
    worldToScreenPt(worldPt) {
        return Utils.worldToScreen(worldPt, this.app.camera.instance, this.app.renderSize.size)
    }
    
    update() {
        // Update 3D objects ...
    }

    toggleCarDriving() {
        this.trigger('toggleCarDriving')
    }

    carStopEngine() {
        this.trigger('carEngineOff')
    }

    changeColor(color) {
        this.trigger('changeColor', [{color}])
    }

    showScreenMenu() {
        this.mainScreen.style.visibility = "visible"
        this.mainScreen.style.opacity = 1
    }

    // showColorButton() {
    //     this.colorButton.instance.style.opacity = 1
    // }
    
    // hideColorButton() {
    //     this.colorButton.instance.style.opacity = 0
    // }
    
    destroy() {
        this.container.destroy()
        this.container = null

        this.colorSelector.destroy()
        this.colorSelector = null

        this.carToggleDrivingButton.destroy()
        this.carToggleDrivingButton = null


        // this.colorButton.destroy()
        // this.colorButton = null

        this.mainScreen = null
        
        this.mainWorld = null

        this.app = null
    }
}
