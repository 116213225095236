import { AmbientLight, CameraHelper, DirectionalLight, DirectionalLightHelper, Group, HemisphereLight, PointLight, SpotLight, SpotLightHelper } from "three"
import { Memory } from "../Utils/Memory"
import { App } from "../App"
import { EventEmitter } from "../Utils/EventEmitter"

const CONFIG = {
    env: 0.8,
    ambient: 0.3, 
    point: 50, 
    sun: 3,
    spot1: 320,
    spot2: 300
}

export class Lights extends EventEmitter {
    constructor() {
        super()

        this.app = new App()

        this.instance = new Group()
        
        this.init()
    }
    
    init() {
        this.app.scene.environment = this.app.assetManager.items.envmap
        // this.app.scene.environmentRotation.set(0, -Math.PI / 2, 0)
        this.app.scene.environmentIntensity = CONFIG.env

        const ambientLight = new AmbientLight(0xf0f0f0, 1)
        ambientLight.name = "ambient"
        ambientLight.color.convertLinearToSRGB()
        ambientLight.intensity = CONFIG[ambientLight.name]
        this.instance.add(ambientLight)
        
        const dirLight = new DirectionalLight(0xeaae76, 1.2)
        dirLight.name = "sun"
        dirLight.color.convertLinearToSRGB()
        dirLight.intensity = CONFIG[dirLight.name]
        dirLight.position.set(0, 3, -2)
        dirLight.castShadow = true
        this.instance.add(dirLight)

        // const pointLight = new PointLight(0xf0f0d0, CONFIG.point)
        // pointLight.name = "point"
        // pointLight.position.set(4, 1.2, -5)
        // this.instance.add(pointLight)
        
        const spotLight = new SpotLight(0xdad6af, 120, 0, 0.68, 0.26)
        spotLight.name = "spot1"
        spotLight.color.convertLinearToSRGB()
        spotLight.intensity = CONFIG[spotLight.name]
        spotLight.position.set(3, 3, 0)
        spotLight.target.position.set(3, 0, 0)
        spotLight.castShadow = true
        this.instance.add(spotLight)

        // const spotLightHelper = new SpotLightHelper( spotLight );
        // this.app.scene.add( spotLightHelper );
        

        const spotLight2 = new SpotLight(0xdad6af, 140, 0, 0.76, 0.2)
        spotLight2.name = "spot2"
        spotLight2.intensity = CONFIG[spotLight2.name]
        spotLight2.color.convertLinearToSRGB()
        spotLight2.position.set(-5, -3, 3)
        spotLight2.target.position.set(-4, 0, 0)
        spotLight2.castShadow = true
        this.instance.add(spotLight2)

        // const spotLightHelper2 = new SpotLightHelper( spotLight2 );
        // this.app.scene.add( spotLightHelper2 );
        
        spotLight.shadow.mapSize.width = 1024
        spotLight.shadow.mapSize.height = 1024

        spotLight.shadow.camera.near = 0.01
        spotLight.shadow.camera.far = 40
        spotLight.shadow.camera.fov = 20


        spotLight2.shadow.mapSize.width = 1024
        spotLight2.shadow.mapSize.height = 1024

        spotLight2.shadow.camera.near = 0.01
        spotLight2.shadow.camera.far = 40
        spotLight2.shadow.camera.fov = 20

        // const camH = new CameraHelper(spotLight.shadow.camera)
        // this.instance.add(camH)

        
        if (this.app.debug.active) {
            const lf = this.app.debug.ui.addFolder("Lights").close()

            lf.add(this.app.scene, "environmentIntensity", 0, 1, 0.01).name("envmap").onChange(() => {
                this.trigger('envmapChanged')
            })

            // lf.add(pointLight, "intensity", 0, 100, 0.5).name("point")
            // lf.add(pointLight.position, "y", 0, 2, 0.01).name("point height")


            // lf.add(hemiLight, "intensity", 0, 5, 0.1).name("sun")
            // lf.addColor(hemiLight, "skyColor").name("sky")
            // lf.addColor(hemiLight, "groundColor").name("ground")

            
            lf.add(ambientLight, "intensity", 0, 5, 0.01).name("ambient")
            lf.addColor(ambientLight, "color").name("ambient color")

            
            lf.add(dirLight, "intensity", 0, 5, 0.01).name("sun")
            lf.addColor(dirLight, "color").name("sun color")
            
            
            // const dirLightH = new DirectionalLightHelper(dirLight)
            // dirLightH.visible = false
            // dirLight.add(dirLightH)
            // lf.add(dirLightH, "visible", false).name("dir helper")
            
            lf.add(spotLight, "intensity", 0, 500, 1).name("spot")
            lf.add(spotLight, "angle", 0, Math.PI /2, 0.01).name("spot angle")
            lf.add(spotLight, "penumbra", 0, 1, 0.01).name("spot penumbra")
            lf.addColor(spotLight, "color").name("spot color")
            
            lf.add(spotLight2, "intensity", 0, 500, 1).name("spot 2")
            lf.add(spotLight2, "angle", 0, Math.PI /2, 0.01).name("spot 2 angle")
            lf.add(spotLight2, "penumbra", 0, 1, 0.01).name("spot 2 penumbra")
            lf.addColor(spotLight2, "color").name("spot 2 color")
            
            const spotLightH = new SpotLightHelper(spotLight)
            spotLightH.visible = false
            spotLight.add(spotLightH)
            lf.add(spotLightH, "visible", false).name("spot helper")
        }
    }
    
    destroy() {
        Memory.clearScene(this.instance)

        this.instance = null
    }
}