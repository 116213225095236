const CAR_DEFINITION = {
    nodes_by_material: {
        'Vitres': [
            'Vitre_Avant',
            'Vitre_Arriere',
            'Vitre_Porte_Avant_1_L',
            'Vitre_Porte_Avant_2_Ouvert_L',
            'Vitre_Porte_Arriere_L',
            'Vitre_Porte_Avant_2_Ferme_R',
            'Vitre_Porte_Avant_1_R',
            'Vitre_Porte_Arriere_R',
        ],

        'Wheels & details': [
            'Rail_Vitre_Porte_Avant_L',
            'Rail_Vitre_Porte_Arriere_L',
            'Rail_Vitre_Porte_Avant_R',
            'Rail_Vitre_Porte_Arriere_R',
            'Retro_Ext_L',
            'Retro_Ext_R',
            'Retro_Int',
            'Retro_Int_Base',
            'Retro_Int_Support',
            'ANM_Roue_Avant_L',
            'ANM_Roue_Avant_R',
            'ANM_Roue_Arriere_L',
            'ANM_Roue_Arriere_R',
            'Poignee_Base_Avant_L',
            'Poignee_Avant_L',
            'Serrure_L',
            'Poignee_Base_Arriere_L',
            'Poignee_Arriere_L',
            'Poignee_Base_Avant_R',
            'Poignee_Avant_R',
            'Serrure_R',
            'Poignee_Base_Arriere_R',
            'Poignee_Arriere_R'
        ],

        'Chassis': [
            'Sol_Int_1',
            'Sol_Int_2',
            'Sol_Int_3',
            'Sol_Int_Portes',
            'Barres_Separation',
            'Sol_Ext_1',
            'Chassis_Base',
            'Roue_Direction_1',
            'Roue_Direction_2',
            'Chassis_Avant_L',
            'Chassis_Avant_R',
            'Chassis_Arriere_L',
            'Chassis_Arriere_R',
            'Tige_Roue_Avant_L',
            'Tige_Roue_Avant_R',
            'Tige_Roue_Arriere_L',
            'Tige_Roue_Arriere_R',
            'Pot_Echappement_Base_L',
            'Pot_Echappement_Fin_L',
            'Pot_Echappement_Corps_L',
            'Pot_Echappement_Base_R',
            'Pot_Echappement_Fin_R',
            'Pot_Echappement_Corps_R'
        ],

        'Interior': [
            'Tableau_de_bord_Base',
            'Tableau_de_bord_Bot',
            'Volant_Base',
            'Volant_1',
            'Volant_2',
            'Compteur_1',
            'Compteur_2',
            'Assise_1_L',
            'Assise_2_L',
            'Dossier_1_L',
            'Dossier_2_L',
            'Assise_1_R',
            'Assise_2_R',
            'Dossier_1_R',
            'Dossier_2_R',
            'Assise_1',
            'Assise_2',
            'Dossier_1',
            'Dossier_2'
        ],

        'Carrosserie 1': [
            'Avant_Base',
            'Avant_Base1',
            'Aile_Avant_Top_L',
            'Aile_Avant_Top_R',
            'Aile_Avant_Bot_L',
            'Aile_Avant_Bot_R',
            'ANM_Capot',
            'Callandres_Base_L',
            'Callandres_Base_R',
            'Parchoc_Avant1',
            'Arriere_Base',
            'Aile_Arriere_Top',
            'Aile_Arriere_Bot_L',
            'Aile_Arriere_Bot_R',
            'ANM_Coffre',
            'Parchoc_Arriere'
        ], 
        
        'Carrosserie 2': [
            'Roue_Bordure_Avant_L',
            'Roue_Bordure_Avant_R',
            'Roue_Bordure_Arriere_L',
            'Roue_Bordure_Arriere_R',
            'Phare_Avant_L_1',
            'Phare_Avant_L_2',
            'Phare_Avant_R_1',
            'Phare_Avant_R_2',
            'Phare_Arriere_Base',
            'Phares_Arriere_L',
            'Phares_Arriere_R',
            'Plaque_Immat_Arr',
            'Callandres_L',
            'Callandres_R',
            'Toit_Ext',
            'Toit_Int',
            'Bordures_Avant',
            'Bordures_Arriere',
            'Bordures_Cote_L',
            'Bordures_Cote_R',
            'Porte_Avant_Top_L',
            'Porte_Avant_Bot_L',
            'Porte_Avant_Int_L',
            'Porte_Avant_Top_R',
            'Porte_Avant_Bot_R',
            'Porte_Avant_Int_R',
            'Porte_Arriere_Top_L',
            'Porte_Arriere_Bot_L',
            'Porte_Arriere_Int_L',
            'Porte_Arriere_Top_R',
            'Porte_Arriere_Bot_R',
            'Porte_Arriere_Int_R'
        ]
    }
}

export default CAR_DEFINITION