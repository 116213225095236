export default [
    // Splashscreen
    {
        name: 'splashscreen',
        type: 'video',
        path: 'video/POP_Splashscreen_v3_512x512.webm',
    },

    // envmap
    {
        name: 'envmap',
        type: 'HDR',
        path: 'envmap/envmap.hdr',
        license: '',
        author: '',
        url: ''
    },

    // Models
    {
        name: 'LowRider',
        type: 'gltf',
        path: 'models/LowRider/Lowrider_HP_6.glb', // v6
        license: '',
        author: 'Ethan Gaudet-Traffy',
    },
    
    // Background textures
    {
        name: 'restaurant',
        type: 'texture',
        envmap: true,
        path: 'background/restaurant.jpg',
        license: '',
        author: '',
    },

    // Ground material
    {
        name: 'ground-wood',
        type: 'material',
        path: 'materials/ground-wood',
        url: '',
        textures: {
            map: 'map.jpg',
            normal: 'normal.jpg',
            alpha: 'alpha.png'
        }
    },

    // Car materials
    {
        name: 'LowRider_Carrosserie_1',
        type: 'material',
        path: 'models/LowRider/materials/LowRider_Carrosserie_1',
        url: '',
        textures: {
            map: {
                'white': 'LowRider_Carrosserie_1_Blanc_BC.png',
                'red': 'LowRider_Carrosserie_1_Rouge_BC.png',
                'black': 'LowRider_Carrosserie_1_Noir_BC.png',
                'mix': 'LowRider_Carrosserie_1_Mix_BC.png',
            },
            normal: 'LowRider_Carrosserie_1_N.png',
            roughness: 'LowRider_Carrosserie_1_R.png',
            metalness: 'LowRider_Carrosserie_1_M.png'
        }
    },

    {
        name: 'LowRider_Carrosserie_2',
        type: 'material',
        path: 'models/LowRider/materials/LowRider_Carrosserie_2',
        url: '',
        textures: {
            map: {
                'white': 'LowRider_Carrosserie_2_Blanc_BC.png',
                'red': 'LowRider_Carrosserie_2_Rouge_BC.png',
                'black': 'LowRider_Carrosserie_2_Noir_BC.png',
                'mix': 'LowRider_Carrosserie_2_Mix_BC.png',
            },
            normal: 'LowRider_Carrosserie_2_N.png',
            roughness: 'LowRider_Carrosserie_2_R.png',
            metalness: 'LowRider_Carrosserie_2_M.png'
        }
    },

    {
        name: 'LowRider_Int',
        type: 'material',
        path: 'models/LowRider/materials/LowRider_Int',
        url: '',
        textures: {
            map: 'LowRider_Int_BC.png',
            normal: 'LowRider_Int_N.png',
            roughness: 'LowRider_Int_R.png',
            metalness: 'LowRider_Int_M.png'
        }
    },

    {
        name: 'LowRider_Roues_Details',
        type: 'material',
        path: 'models/LowRider/materials/LowRider_Roues_Details',
        url: '',
        textures: {
            map: 'LowRider_Roues_Details_BC.png',
            normal: 'LowRider_Roues_Details_N.png',
            roughness: 'LowRider_Roues_Details_R.png',
            metalness: 'LowRider_Roues_Details_M.png',
            alpha: 'LowRider_Roues_Details_inverse_O.png'
        }
    },

    {
        name: 'LowRider_Sols_et_Chassis',
        type: 'material',
        path: 'models/LowRider/materials/LowRider_Sols_et_Chassis',
        url: '',
        textures: {
            map: 'LowRider_Sols_et_Chassis_BC.png',
            normal: 'LowRider_Sols_et_Chassis_N.png',
            roughness: 'LowRider_Sols_et_Chassis_R.png',
            metalness: 'LowRider_Sols_et_Chassis_M.png'
        }
    }
]
