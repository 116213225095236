import { BackSide, LinearSRGBColorSpace, Mesh, MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, RepeatWrapping, Vector2 } from "three"
import { App } from "../App"
import { ColorRGB } from "../Utils/Utils"

export default class Ground {
    constructor() {
        this.instance = null
        this.black = null

        this.app = new App()

        this.init()
    }

    init() {
        const material = new MeshStandardMaterial({
            color: new ColorRGB('#c17d2f'),
            side: BackSide,
            transparent: true,
            opacity: 0
        })

        const geometry = new PlaneGeometry(20, 15)
        this.instance = new Mesh(geometry, material)

        const blackMaterial = new MeshBasicMaterial({
            color: new ColorRGB('#000000'),
            side: BackSide,
            transparent: true,
            opacity: 0,
            depthWrite: false
        })

        this.black = new Mesh(geometry, blackMaterial)
        this.black.scale.set(5, 5, 1)
        this.black.position.set(0, 0, 0.1)

        this.instance.add(this.black)
        
        this.instance.receiveShadow = true
        
        const materialDef = this.app.assetManager.getItem('ground-wood')
        materialDef.map.wrapS = RepeatWrapping
        materialDef.map.wrapT = RepeatWrapping
        materialDef.map.repeat.set(3, 2)
        
        material.map = materialDef.map
        material.normalMap = materialDef.normal
        material.normalScale = new Vector2(0.3, 0.3)
        material.normalMap.repeat.set(3, 2)
        material.normalMap.wrapS = RepeatWrapping
        material.normalMap.wrapT = RepeatWrapping

        material.normalMap.colorSpace = LinearSRGBColorSpace

        material.aoMap = materialDef.ao
        material.alphaMap = materialDef.alpha
        
        material.metalness = 1
        material.roughness = 0.72

        if (this.app.debug.active) {
            const gf = this.app.debug.ui.addFolder('Ground').close()
            gf.addColor(material, 'color').name("Color")
            gf.add(material, 'roughness', 0, 1, 0.01)
            gf.add(material, 'metalness', 0, 1, 0.01)

            const params = { normal: 0.3 }
            gf.add(params, 'normal', 0, 1, 0.01).onChange((value) => {material.normalScale.set(value, value)})
        }
        
        blackMaterial.alphaMap = materialDef.alpha
    }

    update(delta) {
        const deltaSpeed = this.app.car.animDriveSpeed * delta
        this.instance.material.map.offset.x = (this.instance.material.map.offset.x + deltaSpeed) % 1
        this.instance.material.normalMap.offset.x = (this.instance.material.normalMap.offset.x + deltaSpeed) % 1
    }

   destroy() {
        this.black.material.dispose()
        this.black = null

        this.instance.material.map.dispose()
        this.instance.material.normalMap.dispose()
        this.instance.material.dispose()
        this.instance.geometry.dispose()
        
        this.instance = null   
        
        this.app = null   
    }
}
